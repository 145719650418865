import React, {Fragment, useContext, useEffect, useRef, useState} from "react";
import MessageItem from "../components/MessageItem";
import {
    ArrowUpOnSquareIcon,
    BoltIcon,
    BookmarkIcon,
    CloudArrowUpIcon,
    PaperAirplaneIcon
} from "@heroicons/react/16/solid";
import {Message, Role, Workflow} from "../types";
import KnowledgeBaseItem from "../components/KnowledgeBaseItem";
import {v4 as uuidv4} from "uuid";
import {useNavigate} from "react-router-dom";
import {getToken} from "../utils/authentication";
import {UserContext} from "../App";
import WorkflowBar from "../components/WorkflowBar";
import ChatInput from "../components/ChatInput";
import ChatHeader from "../components/ChatHeader";

const sendMessage = async (
    session_id: string,
    knowledge_base_id: string,
    limit_llm_knowledge: boolean,
    messages: { role: string; content: string }[],
) => {
    try {
        const response = await fetch(
            process.env.REACT_APP_API_URL + "/content-creator",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": 'Bearer ' + getToken(),
                    "Cache-Control": "no-cache",
                    Connection: "keep-alive",
                },
                body: JSON.stringify({
                    session_id,
                    knowledge_base_id,
                    messages,
                    limit_llm_knowledge,
                }),
            },
        );
        if (response.ok) {
            const data = await response.json();
            console.log("Antwort erhalten:", data);
            return [data.answer, data.sources];
        } else {
            const data = await response.json();
            console.log(data.detail || "Ein Fehler ist aufgetreten.");
        }
    } catch (error) {
        console.error("Fehler beim Abrufen der Dokumente:", error);
    }
};

const archiveConversation = async (
    selectedKnowledgeBase: any,
    messages: { role: string; content: string }[],
) => {
    try {
        const currentDate = new Date().toLocaleString("de-DE");

        const archiveTitle = selectedKnowledgeBase
            ? `Konversation | ${selectedKnowledgeBase.title} | ${currentDate}`
            : `Konversation | ${currentDate}`;

        const archiveData = {
            title: archiveTitle,
            history: JSON.stringify(messages),
            shared_with_tenant: false,
        };

        const response = await fetch(process.env.REACT_APP_API_URL + "/archives", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": 'Bearer ' + getToken(),
            },
            body: JSON.stringify(archiveData),
        });

        if (response.ok) {
            const data = await response.json();
            console.log("Archivierung erfolgreich:", data);
            return data;
        } else {
            const data = await response.json();
            console.log(data.detail || "Ein Fehler ist aufgetreten.");
        }
    } catch (error) {
        console.error("Fehler beim Archivieren der Konversation:", error);
    }
};

const ContentCreator: React.FC = () => {
    const navigate = useNavigate();
    const conversationEndRef = useRef<HTMLDivElement>(null);

    const [sessionId, setSessionId] = useState<string>(uuidv4());
    const [messages, setMessages] = useState<Message[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [userInput, setUserInput] = useState<string>("");
    const [workflows, setWorkflows] = useState<Workflow[]>([]);

    const [knowledgeBases, setKnowledgeBases] = useState<any[]>([]);
    const [selectedKnowledgeBase, setSelectedKnowledgeBase] = useState<any>(null);

    const [limitLLMKnowledge, setLimitLLMKnowledge] = useState<boolean>(false);

    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const {currentUser} = useContext(UserContext);

    useEffect(() => {
        const fetchWorkflows = async () => {
            try {
                const response = await fetch(
                    process.env.REACT_APP_API_URL + "/workflows/all",
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": 'Bearer ' + getToken(),
                        },
                    },
                );

                if (response.ok) {
                    const data = await response.json();
                    setWorkflows(data);
                } else {
                    const data = await response.json();
                    setErrorMessage(data.detail || "Ein Fehler ist aufgetreten.");
                }
            } catch (error) {
                setErrorMessage("Ein Fehler ist aufgetreten.");
            } finally {
                setLoading(false);
            }
        };

        fetchWorkflows();
    }, []);

    useEffect(() => {
        const fetchKnowledgeBases = async () => {
            try {
                const response = await fetch(
                    process.env.REACT_APP_API_URL + "/knowledgebases/all",
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": 'Bearer ' + getToken(),
                        },
                    },
                );

                if (response.ok) {
                    const data = await response.json();
                    setKnowledgeBases(data);
                } else {
                    const data = await response.json();
                    setErrorMessage(data.detail || "Ein Fehler ist aufgetreten.");
                }
            } catch (error) {
                setErrorMessage("Ein Fehler ist aufgetreten.");
            } finally {
                setLoading(false);
            }
        };

        fetchKnowledgeBases();
    }, []);

    useEffect(() => {
        const newSessionId = uuidv4();
        setSessionId(newSessionId);
    }, []);

    const handleWorkflow = async (workflow: Workflow) => {
        const newMessages = [
            ...messages,
            {
                role: "system",
                content: workflow.context + "\n \n" + workflow.action,
                sources: [],
            },
        ];

        setMessages(newMessages);
        conversationEndRef.current?.scrollIntoView({behavior: "smooth"});
        setLoading(true);

        const response = await sendMessage(
            sessionId,
            selectedKnowledgeBase?.id,
            limitLLMKnowledge,
            newMessages,
        );
        handleResponse(response);
    };

    const handleMessage = async () => {
        if (userInput === "") return;

        const newMessages = [
            ...messages,
            {role: "user", content: userInput, sources: []},
        ];

        setMessages(newMessages);
        setUserInput("");
        setLoading(true);

        const response = await sendMessage(
            sessionId,
            selectedKnowledgeBase?.id,
            limitLLMKnowledge,
            newMessages,
        );
        conversationEndRef.current?.scrollIntoView({behavior: "smooth"});
        handleResponse(response);
    };

    const handleResponse = (response: any) => {
        if (response) {
            setMessages((prevMessages) => [
                ...prevMessages,
                {role: "assistant", content: response[0], sources: response[1]},
            ]);
            conversationEndRef.current?.scrollIntoView({behavior: "smooth"});

            setLoading(false);
        }
    };

    const handleKnowledgeBaseChange = (
        event: React.ChangeEvent<HTMLSelectElement>,
    ) => {
        const selectedId = event.target.value;
        const selectedKB = knowledgeBases.find((kb) => kb.id === selectedId);
        setSelectedKnowledgeBase(selectedKB);
    };

    const handleLimitLLMKnowledgeChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setLimitLLMKnowledge(event.target.checked);
    };

    const handleArchiveClick = async () => {
        const archivedData = await archiveConversation(
            selectedKnowledgeBase,
            messages,
        );
        if (archivedData) {
            setMessages([]);
            setUserInput("");
            setSessionId(uuidv4());
            navigate("/archives");
        }
    };

    return (
        <>
            <div>
                <h2 className="text-xl font-bold text-left mb-3 leading-6 text-gray-900">
                    Content Creator
                </h2>
                <p className="mt-1 mr-4 text-sm font-medium text-left text-gray-500">
                    <span>Contents schrittweise entwickeln</span>
                </p>
                <hr className="z-10 mb-8"/>
            </div>
            <div className="w-full">
                <h2 className="text-lg font-semibold text-center mx-2 mb-4 leading-6 text-gray-900">
                    Domänenwissen wählen
                </h2>
                <p className="mt-1 mb-6 text-sm font-medium text-center text-gray-500">
                    Das ausgewählte Domänenwissen steht der AI zur Verfügung
                </p>
                <div className="mt-4 flex row space-x-4">
                    <select
                        id="knowledgeBase"
                        name="knowledgeBase"
                        className="w-full p-3 font-medium text-blue-600 border-b rounded-xl border-r-12 border-white appearance-none bg-none"
                        onChange={handleKnowledgeBaseChange}
                    >
                        <option value="">Knowledge Base auswählen</option>
                        {knowledgeBases.map((knowledgeBase) => (
                            <option key={knowledgeBase.id} value={knowledgeBase.id}>
                                {knowledgeBase.title}
                            </option>
                        ))}
                    </select>
                </div>
                {selectedKnowledgeBase && (
                    <>
                        <KnowledgeBaseItem {...selectedKnowledgeBase} edit_mode={false}/>
                        <fieldset>
                            <div className="mt-6">
                                <div className="relative flex justify-center">
                                    <div className="flex h-6 items-center">
                                        <input
                                            id="limit-llm-knowledge"
                                            aria-describedby="limit-llm-knowledge-description"
                                            name="limit-llm-knowledge"
                                            type="checkbox"
                                            className="form-checkbox h-5 w-5 rounded text-blue-600 border-none focus:ring-0 focus:ring-offset-0"
                                            onChange={handleLimitLLMKnowledgeChange}
                                        />
                                    </div>
                                    <div className="ml-3 text-sm leading-6">
                                        <label
                                            htmlFor="limit-llm-knowledge"
                                            className="font-medium text-gray-900"
                                        >
                                            AI auf Domänenwissen beschränken
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </>
                )}

                <hr className="z-10"/>
            </div>

            <div>
                <ChatHeader
                    title="Konversation"
                    messageCount={messages.length}
                    onArchiveClick={handleArchiveClick}
                    exportEnabled={currentUser?.tenant.supports_miles_export}
                    onExportClick={() => alert("Implementierung in Arbeit")}
                />

                <div className="mt-6 pb-72">
                    <div>
                        {messages?.map(
                            (message, i) =>
                                message.role !== "system" && (
                                    <MessageItem {...message} key={i}/>
                                ),
                        )}
                    </div>

                    <div ref={conversationEndRef} className="pt-12 pb-20"></div>
                </div>
            </div>

            <div className="fixed bottom-0 left-0 right-0 z-10 lg:pl-72">
                <div className="px-6 lg:px-8 m-auto w-full max-w-[880px]">
                    <div className="rounded-t-xl py-4 backdrop-blur-md bg-gray-200/75 w-full">
                        <WorkflowBar
                            workflows={workflows}
                            currentUser={currentUser}
                            onWorkflowClick={handleWorkflow}
                            context="creator"
                        />

                        <ChatInput
                            userInput={userInput}
                            onInputChange={setUserInput}
                            onSendMessage={handleMessage}
                            loading={loading}
                            disabled={false}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default ContentCreator;
