import React, {useContext, useEffect, useState} from "react";

const About: React.FC = () => {

    return (
        <div className="pb-14">
            <div>
                <h2 className="text-xl font-bold text-left mb-3 leading-6 text-gray-900">
                    Content AI
                </h2>
                <p className="mt-1 text-sm font-medium text-left text-gray-500">
                    <span>Grundlegende Informationen</span>
                </p>
                <hr className="z-10 mb-8"/>
                <p className="mt-1 text-sm font-medium text-left text-gray-500">
                    Diese Anwendung basiert auf den Erkenntnissen der Masterarbeit <a
                    href="https://permalink.obvsg.at/fhj/AC17341021" target="_blank" className="text-blue-600">„Automatisierung
                    der Content-Entwicklung mittels Large Language Models“</a> von Ramon Tippl. Die Umsetzung und
                    Weiterentwicklung erfolgt im Auftrag der MILES Learning GmbH. Im Zentrum steht die Anwendung von
                    Prompt Engineering, In-Context Learning sowie Retrieval-Augmented
                    Generation (RAG).
                </p>
                <p className="mt-6 text-sm font-medium text-left text-gray-500">
                    © MILES Learning GmbH
                </p>
            </div>
        </div>
    );
};

export default About;
