import React, {useCallback} from "react";
import {MagnifyingGlassIcon} from "@heroicons/react/16/solid";

interface SearchBarProps {
    searchTerm: string;
    onSearchChange: (value: string) => void;
}

const SearchBar: React.FC<SearchBarProps> = ({searchTerm, onSearchChange}) => {
    const handleInputChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            onSearchChange(event.target.value);
        },
        [onSearchChange]
    );

    return (
        <div className="flex items-center mt-6">
            <div className="relative w-full">
                <input
                    type="text"
                    value={searchTerm}
                    onChange={handleInputChange}
                    placeholder="Suchen …"
                    className="w-full px-4 py-3 border-gray-200 bg-white rounded-xl pl-11 placeholder-gray-400  outline-none focus:outline-none focus:ring-0 focus:border-gray-200"
                    autoFocus
                />
                <MagnifyingGlassIcon className="w-6 h-6 absolute left-3 top-3 text-gray-400"/>
            </div>
        </div>
    );
};

export default SearchBar;
