import React, {useContext, useState} from "react";
import {SubmitHandler, useForm} from "react-hook-form";
import {Outlet, useNavigate} from "react-router-dom";
import {saveToken, saveUser} from "../utils/authentication";
import {UserContext} from "../App";
import Login from "./Login";
import {ChatBubbleBottomCenterTextIcon, ChatBubbleOvalLeftEllipsisIcon} from "@heroicons/react/16/solid";

const Error: React.FC = () => {


    return (
        <div className="flex min-h-screen flex-1 items-center justify-center py-12 sm:px-6 lg:px-8 flex-col">
            <a href="/login">

                <div className="flex flex-row justify-center items-center">
                    <ChatBubbleOvalLeftEllipsisIcon
                        className="h-14 w-auto text-blue-500"
                        aria-hidden="true"
                    />
                    <h2 className="ml-4 text-3xl font-bold leading-9 tracking-tight text-gray-900">
                        Content AI
                    </h2>
                </div>
            </a>
            <h1 className="h-14 w-auto text-gray-900 mt-20 text-lg">Seite wurde nicht gefunden.</h1>
            <h2 className="h-14 w-auto text-blue-500 font-bold"><a href="/">zurück zur Startseite</a></h2>
            <footer className="mt-20 text-center text-gray-400 text-xs">
                © MILES Learning GmbH | {process.env.REACT_APP_VERSION}
            </footer>
        </div>
    );
};

export default Error;
