import React, {useState} from "react";
import {BoltIcon, ChevronDownIcon, ChevronUpIcon} from "@heroicons/react/16/solid";
import {Workflow, Role} from "../types";
import {XMarkIcon} from "@heroicons/react/24/outline";

interface WorkflowBarProps {
    workflows: Workflow[];
    currentUser: any;
    onWorkflowClick: (workflow: Workflow) => void;
    context: "creator" | "converter";
    defaultExpanded?: boolean;
    disabled?: boolean;
}

const WorkflowBar: React.FC<WorkflowBarProps> = ({
                                                     workflows,
                                                     currentUser,
                                                     onWorkflowClick,
                                                     context,
                                                     defaultExpanded = false,
                                                     disabled = false,
                                                 }) => {

    const [isExpanded, setIsExpanded] = useState(defaultExpanded);

    const filteredWorkflows = workflows.filter((workflow) =>
        context === "creator" ? workflow.visible_for_creator : workflow.visible_for_converter
    );

    return (
        <div className="w-full">
            <div className="flex justify-between items-center">
                {!disabled ? (
                    <button
                        onClick={() => setIsExpanded(!isExpanded)}
                        className="text-blue-600 hover:text-blue-800 font-medium text-sm outline-0 items-center justify-center content-center"
                    >
                        <div
                            className='px-2 mx-1 mb-2 rounded-xl text-sm font-medium cursor-pointer flex items-center'>
                            <BoltIcon className="h-4 w-4 mr-1"/>
                            <span className="mr-1">Workflows</span>
                            {isExpanded ? (<ChevronDownIcon className="h-5 w-5"/>) : (
                                <ChevronUpIcon className="h-5 w-5"/>)}
                        </div>
                    </button>
                ) : (
                    <button
                        className="text-gray-500 font-medium text-sm outline-0 items-center justify-center content-center"
                    >
                        <div
                            className='px-2 mx-1 mb-2 rounded-xl text-sm font-medium flex items-center cursor-not-allowed'>
                            <BoltIcon className="h-4 w-4 mr-1"/>
                            <span className="mr-1">Workflows</span>
                        </div>
                    </button>
                )}
            </div>

            {/* Workflow-Liste */}
            {isExpanded && (
                <div className="flex flex-wrap justify-start mt-1">
                    {filteredWorkflows.map(
                        (workflow, i) =>
                            !workflow.is_disabled &&
                            (!workflow.is_template || currentUser?.role === Role.SuperAdmin) && (
                                <div
                                    onClick={() => onWorkflowClick(workflow)}
                                    className={`py-1.5 px-2 mx-1 mb-2 rounded-xl text-sm text-white font-medium cursor-pointer flex items-center ${
                                        workflow.is_template
                                            ? "hover:bg-gray-600 bg-gray-500"
                                            : "hover:bg-blue-700 bg-blue-600"
                                    }`}
                                    key={i}
                                >
                                    <BoltIcon className="h-4 w-4 mr-1"></BoltIcon>
                                    <span>{workflow.title}</span>
                                </div>
                            )
                    )}
                </div>
            )}
        </div>
    );
};

export default WorkflowBar;
