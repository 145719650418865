import React, {useEffect, useState, useCallback} from "react";
import ArchiveItem from "../components/ArchiveItem";
import {Archive} from "../types";
import {getToken} from "../utils/authentication";
import SearchBar from "../components/SearchBar";
import PaginationBar from "../components/PaginationBar";

const Archives: React.FC = () => {
    const [archives, setArchives] = useState<Archive[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const [searchTerm, setSearchTerm] = useState<string>("");
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);

    useEffect(() => {
        const timer = setTimeout(() => {
            setDebouncedSearchTerm(searchTerm);
        }, 300);

        return () => clearTimeout(timer);
    }, [searchTerm]);

    const fetchArchives = useCallback(async () => {
        setLoading(true);
        setErrorMessage(null);

        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/archives?search=${debouncedSearchTerm}&page=${currentPage}&size=10`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${getToken()}`,
                    },
                }
            );

            if (response.ok) {
                const data = await response.json();
                setArchives(data.items);
                setTotalPages(data.pages);
            } else {
                const data = await response.json();
                setErrorMessage(data.detail || "Ein Fehler ist aufgetreten.");
            }
        } catch (error) {
            setErrorMessage("Ein Fehler ist aufgetreten.");
        } finally {
            setLoading(false);
        }
    }, [debouncedSearchTerm, currentPage]);

    useEffect(() => {
        fetchArchives();
    }, [fetchArchives]);

    const handleSearchChange = useCallback((value: string) => {
        setSearchTerm(value);
        setCurrentPage(1);
    }, []);

    const handlePageChange = (newPage: number) => {
        setCurrentPage(newPage);
    };

    if (errorMessage) {
        return (
            <div className="mt-1 text-sm text-center text-gray-500">{errorMessage}</div>
        );
    }

    return (
        <div className="pb-14">
            <div className="flex justify-between mb-4">
                <div>
                    <h2 className="text-xl font-bold text-left mb-2 leading-6 text-gray-900">
                        Archive
                    </h2>
                    <p className="mt-1 text-sm font-medium text-left text-gray-500">
                        Sicherung vergangener Konversationen
                    </p>
                </div>
            </div>

            <SearchBar searchTerm={searchTerm} onSearchChange={handleSearchChange}/>

            <hr className="z-10 my-6"/>

            {loading ? (
                <div className="animate-ping rounded-full bg-slate-300 h-10 w-10 m-auto"></div>
            ) : (
                <>
                    {archives.map((archive) => (
                        <ArchiveItem {...archive} key={archive.id}/>
                    ))}
                    <PaginationBar
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                    />
                </>
            )}
        </div>
    );
};

export default Archives;