import React, {useCallback, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {Tenant} from "../types";
import {BuildingOfficeIcon} from "@heroicons/react/16/solid";
import {getToken} from "../utils/authentication";
import SearchBar from "../components/SearchBar";
import PaginationBar from "../components/PaginationBar";

const Tenants: React.FC = () => {
    const [tenants, setTenants] = useState<Tenant[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const [searchTerm, setSearchTerm] = useState<string>("");
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);

    useEffect(() => {
        const timer = setTimeout(() => {
            setDebouncedSearchTerm(searchTerm);
        }, 300);

        return () => clearTimeout(timer);
    }, [searchTerm]);

    const fetchTenants = useCallback(async () => {
        setLoading(true);
        setErrorMessage(null);

        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/tenants?search=${debouncedSearchTerm}&page=${currentPage}&size=10`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${getToken()}`,
                    },
                }
            );

            if (response.ok) {
                const data = await response.json();
                setTenants(data.items);
                setTotalPages(data.pages);
            } else {
                const errorData = await response.json();
                setErrorMessage(errorData.detail || "Ein Fehler ist aufgetreten.");
            }
        } catch (error) {
            setErrorMessage("Ein Fehler ist aufgetreten.");
        } finally {
            setLoading(false);
        }
    }, [debouncedSearchTerm, currentPage]);

    useEffect(() => {
        fetchTenants();
    }, [fetchTenants]);

    const handleSearchChange = useCallback((value: string) => {
        setSearchTerm(value);
        setCurrentPage(1);
    }, []);

    const handlePageChange = (newPage: number) => {
        setCurrentPage(newPage);
    };

    return (
        <div className="pb-14">
            <div className="flex justify-between">
                <div>
                    <h2 className="text-xl font-bold text-left mb-3 leading-6 text-gray-900">
                        Tenants
                    </h2>
                    <p className="mt-1 mr-4 text-sm font-medium text-left text-gray-500">
                        <span className="mr-3">Verwaltung aller Tenants</span>
                        <span
                            className="my-2 inline-flex items-center rounded-md px-2 py-1 text-xs font-medium border border-blue-700/20 bg-blue-50 text-blue-700">
                            Super Admin Privileg
                        </span>
                    </p>
                </div>
                <Link to="/tenants/create">
                    <button
                        className="flex justify-center items-center ml-auto mt-5 py-2 px-3 mb-2 rounded-xl text-sm text-white font-medium cursor-pointer hover:bg-blue-700 bg-blue-600"
                    >
                        <BuildingOfficeIcon className="w-4 h-4 mr-1.5"/>
                        Tenant erstellen
                    </button>
                </Link>
            </div>

            <SearchBar searchTerm={searchTerm} onSearchChange={handleSearchChange}/>

            <hr className="z-10 my-6"/>

            {errorMessage && (
                <div className="mt-1 text-sm text-center text-gray-500">{errorMessage}</div>
            )}

            {loading ? (
                <div className="animate-ping rounded-full bg-slate-300 h-10 w-10 m-auto"></div>
            ) : tenants.length > 0 && (
                <div className="overflow-x-auto rounded-xl">
                    <table className="min-w-full divide-y divide-gray-200 bg-white border-separate rounded-xl">
                        <thead>
                        <tr>
                            <th className="px-5 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">
                                ID
                            </th>
                            <th className="px-5 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">
                                Name
                            </th>
                            <th className="px-5 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">
                                Prompt
                            </th>
                            <th className="px-5 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">
                                Miles-Export
                            </th>
                            <th className="px-5 py-3 text-right text-xs font-semibold text-gray-900 uppercase tracking-wider">
                                Aktionen
                            </th>
                        </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                        {tenants.map((tenant) => (
                            <tr key={tenant.id}>
                                <td className="px-5 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {tenant.id}
                                </td>
                                <td className="px-5 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {tenant.name}
                                </td>
                                <td className="px-5 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {tenant.tenant_prompt?.slice(0, 25)}
                                    {tenant.tenant_prompt?.length > 25 ? "..." : ""}
                                </td>
                                <td className="px-5 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {tenant.supports_miles_export ? "Ja" : "Nein"}
                                </td>
                                <td className="px-5 py-4 whitespace-nowrap text-right text-sm font-medium">
                                    <Link
                                        to={`/tenants/edit/${tenant.id}`}
                                        className="text-blue-600 hover:text-blue-900"
                                    >
                                        bearbeiten
                                    </Link>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            )}

            <PaginationBar
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
            />
        </div>
    );
};

export default Tenants;