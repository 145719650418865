import React from "react";
import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/16/solid";

interface PaginationBarProps {
    currentPage: number;
    totalPages: number;
    onPageChange: (newPage: number) => void;
}

const PaginationBar: React.FC<PaginationBarProps> = ({currentPage, totalPages, onPageChange}) =>
    totalPages > 1 ? (
        <div className="flex justify-center items-center mt-6">
            <div className="flex items-center space-x-4">
                <button
                    disabled={currentPage === 1}
                    onClick={() => onPageChange(currentPage - 1)}
                    className={`flex items-center justify-center w-8 h-8 rounded-xl ${
                        currentPage === 1
                            ? "bg-gray-300"
                            : "bg-blue-600 hover:bg-blue-600"
                    } text-white disabled:opacity-50`}
                >
                    <ChevronLeftIcon className="w-5 h-5"/>
                </button>
                <span className="text-sm font-normal text-gray-500">
                    Page {currentPage} von {totalPages}
                </span>
                <button
                    disabled={currentPage === totalPages}
                    onClick={() => onPageChange(currentPage + 1)}
                    className={`flex items-center justify-center w-8 h-8 rounded-xl ${
                        currentPage === totalPages
                            ? "bg-gray-300"
                            : "bg-blue-600 hover:bg-blue-600"
                    } text-white disabled:opacity-50`}
                >
                    <ChevronRightIcon className="w-5 h-5"/>
                </button>
            </div>
        </div>
    ) : null;


export default PaginationBar;
