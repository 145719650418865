import React from "react";
import {ArchiveBoxIcon, ArrowUpOnSquareIcon, BookmarkIcon} from "@heroicons/react/16/solid";

interface ChatHeaderProps {
    title: string;
    messageCount: number;
    onArchiveClick: () => void;
    onExportClick?: () => void;
    exportEnabled?: boolean;
}

const ChatHeader: React.FC<ChatHeaderProps> = ({
                                                   title,
                                                   messageCount,
                                                   onArchiveClick,
                                                   onExportClick,
                                                   exportEnabled = false,
                                               }) => {
    return (
        <div
            className="sticky top-0 z-20 rounded-b-xl py-4 backdrop-blur-md bg-gray-200/75 w-full flex justify-center items-center">
            {exportEnabled && (
                <button
                    className={`flex justify-center items-center text-sm ${
                        messageCount > 1
                            ? "text-blue-600 hover:text-blue-700 font-medium cursor-pointer"
                            : "text-gray-500 cursor-not-allowed"
                    }`}
                    onClick={messageCount > 1 ? onExportClick : undefined}
                    disabled={messageCount <= 1}
                >
                    <ArrowUpOnSquareIcon className="w-4 h-4 mr-2"/>
                    exportieren
                </button>
            )}
            <h2 className="text-lg font-semibold text-center lg:mx-20 mx-4 md:mx-12 leading-6 text-gray-900">
                {title}
            </h2>
            <button
                className={`flex justify-center items-center text-sm ${
                    messageCount > 1
                        ? "text-blue-600 hover:text-blue-700 font-medium cursor-pointer"
                        : "text-gray-500 cursor-not-allowed"
                }`}
                onClick={messageCount > 1 ? onArchiveClick : undefined}
                disabled={messageCount <= 1}
            >
                <ArchiveBoxIcon className="w-4 h-4 mr-2"/>
                archivieren
            </button>
        </div>
    );
};

export default ChatHeader;