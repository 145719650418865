import React, {useContext, useEffect, useState} from "react";
import {getCurrentUser, getToken, saveUser} from "../utils/authentication";
import {ChevronLeftIcon} from "@heroicons/react/16/solid";
import {MilesApiKey, Role, Tenant, User} from "../types";
import {UserContext} from "../App";


const Account: React.FC = () => {
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const {currentUser} = useContext(UserContext);
    const [tenantUsers, setTenantUsers] = useState<User[]>([]);

    useEffect(() => {
        const fetchTenantUsers = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_API_URL + "/tenants/" + currentUser?.tenant.id + "/users", {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": 'Bearer ' + getToken(),
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    setTenantUsers(data);
                } else {
                    const data = await response.json();
                    setErrorMessage(data.detail || "Ein Fehler ist aufgetreten.");
                }
            } catch (error) {
                setErrorMessage("Ein Fehler ist aufgetreten.");
            } finally {
                setLoading(false);
            }
        };

        fetchTenantUsers();
    }, []);


    return (
        <div className="pb-14">
            <div>
                <h2 className="text-xl font-bold text-left mb-3 leading-6 text-gray-900">
                    {currentUser?.tenant.name}
                </h2>
                <p className="mt-1 mr-4 text-sm font-medium text-left text-gray-500">
                    <span>Informationen zum Tenant</span>
                </p>
                <hr className="z-10 mb-8"/>
            </div>
            <div className="border-b rounded-xl bg-white p-5 mt-6">
                <div className="mb-6">
                    <label htmlFor="tenant_id"
                           className="block text-sm font-medium leading-6 text-gray-900">Bezeichnung</label>
                    <input
                        id="tenant_id"
                        type="text"
                        value={currentUser?.tenant.name || ""}
                        readOnly
                        className="block w-full bg-gray-100 rounded-md border-0 mt-2 py-1.5 text-gray-500 ring-1 ring-inset ring-gray-300 focus:ring-inset focus:ring-gray-300 sm:text-sm sm:leading-6"
                    />
                </div>
                <span className="block text-sm font-medium leading-6 text-gray-900">Benutzer:innen</span>
                <div className="w-full flex flex-wrap justify-start mt-2">
                    {tenantUsers?.map(
                        (user, i) =>
                            <div
                                className="py-1 px-2 mr-2 mb-2 rounded-lg text-sm font-medium border border-blue-700/20 bg-blue-50 text-blue-700"
                                key={i}
                            >
                                {user.first_name} {user.last_name}
                            </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Account;
