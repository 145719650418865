import React, {useCallback, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {MilesApiKey} from "../types";
import {getToken} from "../utils/authentication";
import SearchBar from "../components/SearchBar";
import PaginationBar from "../components/PaginationBar";
import {KeyIcon} from "@heroicons/react/16/solid";

const Interfaces: React.FC = () => {
    const [apiKeys, setApiKeys] = useState<MilesApiKey[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);

    const [searchTerm, setSearchTerm] = useState<string>("");
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);

    useEffect(() => {
        const timer = setTimeout(() => {
            setDebouncedSearchTerm(searchTerm);
        }, 300);

        return () => clearTimeout(timer);
    }, [searchTerm]);

    const fetchApiKeys = useCallback(async () => {
        setLoading(true);
        setErrorMessage(null);

        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/miles-api-keys?search=${debouncedSearchTerm}&page=${currentPage}&size=10`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${getToken()}`,
                    },
                }
            );

            if (response.ok) {
                const data = await response.json();
                setApiKeys(data.items);
                setTotalPages(data.pages);
            } else {
                const errorData = await response.json();
                setErrorMessage(errorData.detail || "Ein Fehler ist aufgetreten.");
            }
        } catch (error) {
            setErrorMessage("Ein Fehler ist aufgetreten.");
        } finally {
            setLoading(false);
        }
    }, [debouncedSearchTerm, currentPage]);

    useEffect(() => {
        fetchApiKeys();
    }, [fetchApiKeys]);

    const handleDeleteApiKey = async (id: string) => {
        setLoading(true);
        setSuccessMessage(null);
        setErrorMessage(null);

        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/miles-api-keys/${id}`,
                {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${getToken()}`,
                    },
                }
            );

            if (response.ok) {
                setApiKeys((prev) => prev.filter((key) => key.id !== id));
                setSuccessMessage("API-Key erfolgreich gelöscht!");
            } else {
                const errorData = await response.json();
                setErrorMessage(errorData.detail || "Ein Fehler ist aufgetreten.");
            }
        } catch (error) {
            setErrorMessage("Ein Fehler ist aufgetreten.");
        } finally {
            setLoading(false);
        }
    };

    const handleSearchChange = useCallback((value: string) => {
        setSearchTerm(value);
        setCurrentPage(1);
    }, []);

    const handlePageChange = (newPage: number) => {
        setCurrentPage(newPage);
    };

    return (
        <div className="pb-14">
            <div className="flex justify-between">
                <div>
                    <h2 className="text-xl font-bold text-left mb-3 leading-6 text-gray-900">
                        Schnittstellen
                    </h2>
                    <p className="mt-1 mr-4 text-sm font-medium text-left text-gray-500">
                        MILES API-Keys
                    </p>
                </div>
                <Link to="/interfaces/create">
                    <button
                        className="flex justify-center items-center ml-auto mt-5 py-2 px-3 mb-2 rounded-xl text-sm text-white font-medium cursor-pointer hover:bg-blue-700 bg-blue-600"
                    >
                        <KeyIcon className="w-4 h-4 mr-1.5"/>
                        API-Key hinzufügen
                    </button>
                </Link>
            </div>

            <SearchBar searchTerm={searchTerm} onSearchChange={handleSearchChange}/>

            <hr className="z-10 my-6"/>

            {errorMessage && (
                <div className="mt-1 text-sm text-center text-gray-500">{errorMessage}</div>
            )}
            {successMessage && (
                <div className="mt-1 text-sm text-center text-green-500">{successMessage}</div>
            )}

            {loading ? (
                <div className="animate-ping rounded-full bg-slate-300 h-10 w-10 m-auto"></div>
            ) : apiKeys.length > 0 && (
                <div className="overflow-x-auto rounded-xl">
                    <table className="min-w-full divide-y divide-gray-200 bg-white border-separate rounded-xl">
                        <thead>
                        <tr>
                            <th className="px-5 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">
                                MILES Benutzer:in
                            </th>
                            <th className="px-5 py-4 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">
                                ID
                            </th>
                            <th className="px-5 py-4 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">
                                Secret
                            </th>
                            <th className="px-5 py-4 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">
                                Erstellt
                            </th>
                            <th className="px-5 py-4 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">
                                Aktion
                            </th>
                        </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                        {apiKeys.map((key) => (
                            <tr key={key.id}>
                                <td className="px-5 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {key.title}
                                </td>
                                <td className="px-5 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {key.key}
                                </td>
                                <td className="px-5 py-4 whitespace-nowrap text-sm text-gray-500">
                                    ••••••••
                                </td>
                                <td className="px-5 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {new Date(key.created_at).toLocaleDateString()}
                                </td>
                                <td className="px-5 py-4 whitespace-nowrap text-sm text-gray-500 font-medium">
                                    <button
                                        onClick={() => handleDeleteApiKey(key.id)}
                                        className="text-red-500 hover:text-red-700"
                                    >
                                        löschen
                                    </button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            )}

            <PaginationBar
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
            />
        </div>
    );
};

export default Interfaces;