import React from "react";
import {PaperAirplaneIcon} from "@heroicons/react/16/solid";

interface ChatInputProps {
    userInput: string;
    onInputChange: (value: string) => void;
    onSendMessage: () => void;
    loading: boolean;
    placeholder?: string;
    disabled?: boolean;
}

const ChatInput: React.FC<ChatInputProps> = ({
                                                 userInput,
                                                 onInputChange,
                                                 onSendMessage,
                                                 loading,
                                                 placeholder = "Prompt …",
                                                 disabled = false,
                                             }) => {
    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (!disabled && !loading && (e.metaKey || e.ctrlKey) && e.key === "Enter") {
            onSendMessage();
        }
    };

    return (
        <form>
            <div
                className={`flex items-center py-2 rounded-xl ${
                    loading || disabled ? "bg-gray-300 cursor-not-allowed" : "bg-white"
                } mt-2`}
            >
                <textarea
                    id="chat"
                    value={userInput}
                    className="block mx-2 p-2.5 w-full h-26 text-md text-gray-900 bg-transparent rounded-xl font-medium border-none focus:ring-0 focus:ring-offset-0 disabled:cursor-not-allowed disabled:opacity-50"
                    placeholder={placeholder}
                    onChange={(e) => onInputChange(e.target.value)}
                    onKeyDown={handleKeyDown}
                    disabled={loading || disabled}
                ></textarea>
                <div
                    className={`inline-flex justify-center p-2 mr-2 rounded-full ${
                        loading || disabled
                            ? "text-gray-500 cursor-not-allowed"
                            : "text-blue-600 hover:bg-blue-100 cursor-pointer"
                    }`}
                    onClick={() => !loading && !disabled && onSendMessage()}
                >
                    {loading ? (
                        <span className="animate-ping mr-1 w-7 h-7 rounded-full bg-blue-600 opacity-60"></span>
                    ) : (
                        <PaperAirplaneIcon className="h-6 w-6" aria-hidden="true"/>
                    )}
                </div>
            </div>
        </form>
    );
};

export default ChatInput;