import React, {useEffect, useState} from "react";
import "./MessageItem.css";
import {
    ChevronUpIcon,
    DocumentTextIcon,
    EyeIcon,
    EyeSlashIcon,
    LockClosedIcon,
    ShareIcon,
} from "@heroicons/react/16/solid";
import {Link} from "react-router-dom";
import {Document, KnowledgeBase} from "../types";
import {ArrowPathIcon, XMarkIcon} from "@heroicons/react/24/outline";
import {formatDate, getExtension} from "../utils/utils";
import {getToken} from "../utils/authentication";

interface KnowledgeBaseItemProps extends KnowledgeBase {
    edit_mode: boolean;
}

const KnowledgeBaseItem: React.FC<KnowledgeBaseItemProps> = ({
                                                                 id,
                                                                 user,
                                                                 tenant,
                                                                 title,
                                                                 description,
                                                                 context,
                                                                 shared_with_tenant,
                                                                 edit_mode,
                                                                 created_at
                                                             }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [documents, setDocuments] = useState<Document[]>([]);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [uploading, setUploading] = useState(false);
    const [uploadSuccess, setUploadSuccess] = useState(false);

    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const fetchDocuments = async () => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/knowledgebases/${id}/documents`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": 'Bearer ' + getToken(),
                    },
                },
            );

            if (response.ok) {
                const data = await response.json();
                setDocuments(data);
            } else {
                const data = await response.json();
                setErrorMessage(data.detail || "Ein Fehler ist aufgetreten.");
            }
        } catch (error) {
            setErrorMessage("Ein Fehler ist aufgetreten.");
        }
    };

    useEffect(() => {
        fetchDocuments();
    }, [id]); // id als Abhängigkeit hinzufügen

    useEffect(() => {
        if (uploadSuccess) {
            fetchDocuments();
            setUploadSuccess(false);
        }
    }, [uploadSuccess]);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            setSelectedFile(event.target.files[0]);
        }
    };

    const handleUpload = async () => {
        if (!selectedFile) return;

        setUploading(true);

        const formData = new FormData();
        formData.append("files", selectedFile);

        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/knowledgebases/${id}/documents`,
                {
                    method: "POST",
                    headers: {
                        "Authorization": 'Bearer ' + getToken(),
                    },
                    body: formData,
                },
            );
            if (response.ok) {
                setUploadSuccess(true);
            } else {
                const data = await response.json();
                setErrorMessage(data.detail || "Ein Fehler ist aufgetreten.");
            }
        } catch (error) {
            setErrorMessage("Ein Fehler ist aufgetreten.");
        } finally {
            setUploading(false);
            setSelectedFile(null);
        }
    };

    const handleDelete = async (document_id: string) => {
        const confirmDelete = window.confirm(
            "Soll das Dokument wirklich gelöscht werden?",
        );
        if (!confirmDelete) {
            return;
        }

        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/documents/${document_id}`,
                {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": 'Bearer ' + getToken(),
                    },
                }
            );

            if (response.ok) {
                fetchDocuments();
            } else {
                const data = await response.json();
                setErrorMessage(data.detail || "Ein Fehler ist aufgetreten.");
            }
        } catch (error) {
            setErrorMessage("Ein Fehler ist aufgetreten.");
        }
    };

    const handleView = async (document_id: string) => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/documents/${document_id}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": 'Bearer ' + getToken(),
                    },
                },
            );

            if (response.ok) {
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.download = document_id;
                document.body.appendChild(link);
                link.click();
                link.remove();
            } else {
                const data = await response.json();
                setErrorMessage(data.detail || "Ein Fehler ist aufgetreten.");
            }
        } catch (error) {
            setErrorMessage("Ein Fehler ist aufgetreten.");
        }
    };

    const toggleAccordion = () => setIsOpen(!isOpen);

    useEffect(() => {
        if (selectedFile) {
            handleUpload();
        }
    }, [selectedFile]);

    return (
        <div className="mt-5">
            <button
                type="button"
                className={`flex items-center justify-between w-full p-3 pl-4 text-left font-semibold font-base text-gray-900 border-b rounded-xl bg-white cursor-auto + ${
                    isOpen ? "rounded-b-none" : ""
                }`}
            >
                {edit_mode ? <span>{title}</span> : <span>Details anzeigen</span>}
                <div className="flex items-center">
                    {shared_with_tenant &&
                        <ShareIcon className="h-5 w-5 mr-4 text-gray-400"
                                   aria-hidden="true"/>
                    }
                    <ChevronUpIcon
                        className={`ml-2 w-7 h-7 ${isOpen ? "rotate-0" : "rotate-180"} shrink-0 cursor-pointer text-gray-600 hover:text-gray-700`}
                        onClick={toggleAccordion}
                    />
                </div>
            </button>
            <div className={`${isOpen ? "block" : "hidden"}`}>
                <div className="border-gray-20 bg-white rounded-b-xl">
                    <div className="rounded-xl bg-white p-5">
                        {edit_mode && (
                            <div className="flex justify-end">
                                <Link
                                    to={`/knowledgebases/edit/${id}`}
                                    className="inline-block"
                                >
                                    <button
                                        className="py-2 px-3 rounded-xl text-sm text-white font-medium cursor-pointer hover:bg-gray-600 bg-gray-500">
                                        bearbeiten
                                    </button>
                                </Link>
                            </div>
                        )}

                        <div className="mb-4">
                            <div className="block font-semibold leading-6 text-sm text-gray-900">
                                Beschreibung
                            </div>
                            <div className="mt-2">
                                <div className="block w-full border-0 py-2 text-gray-900 sm:leading-6">
                                    {description}
                                </div>
                            </div>
                        </div>

                        <div className="mb-4">
                            <div className="block font-semibold leading-6 text-sm text-gray-900">
                                Kontext
                            </div>
                            <div className="mt-2">
                                <div
                                    className="block w-full border-0 py-2 text-gray-900 sm:leading-6 whitespace-break-spaces">
                                    {context}
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className="block font-semibold leading-6 text-sm text-gray-900">
                                Dokumente
                            </div>
                            <div className="mt-4">
                                {documents?.map((document, i) => (
                                    <div
                                        className="w-full p-2 mb-2 flex justify-between border border-gray-200 rounded-xl"
                                        key={i}
                                    >
                                        <div className="flex">
                                            <div
                                                className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-500 ring-1 ring-inset ring-blue-600/20 mr-3">
                                                {getExtension(document.title)}
                                            </div>
                                            <div className="text-gray-900 sm:leading-6">
                                                {document.title}
                                            </div>
                                        </div>
                                        <div className="w-24 flex justify-end space-x-2">
                                            <button
                                                type="button"
                                                onClick={() => handleView(document.id)}
                                                className="inline-flex items-center rounded-md bg-blue-600 hover:bg-blue-700 px-2 py-1 text-xs font-medium"
                                            >
                                                <EyeIcon
                                                    className="mx-auto h-4 w-4 text-white"
                                                    aria-hidden="true"
                                                ></EyeIcon>
                                            </button>
                                            {edit_mode && (
                                                <button
                                                    type="button"
                                                    onClick={() => handleDelete(document.id)}
                                                    className="inline-flex items-center rounded-md bg-red-500 hover:bg-red-600 px-2 py-1 text-xs font-medium"
                                                >
                                                    <XMarkIcon
                                                        className="mx-auto h-4 w-4 text-white"
                                                        aria-hidden="true"
                                                    ></XMarkIcon>
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        {edit_mode && (
                            <div className="col-span-full">
                                <div className="flex row justify-between my-4">
                                    {uploading && (
                                        <ArrowPathIcon className="animate-spin h-6 w-6 text-blue-600 mr-4"/>
                                    )}
                                </div>
                                <div className="flex justify-center rounded-2xl border border-gray-200 px-6 py-10">
                                    <div>
                                        <DocumentTextIcon
                                            className="mx-auto h-12 w-12 text-gray-300"
                                            aria-hidden="true"
                                        />
                                        <div className="flex justify-center mt-4 text-sm leading-6 text-gray-600">
                                            <label
                                                htmlFor={`file-upload-${id}`}
                                                className="relative cursor-pointer rounded-md bg-white font-semibold text-blue-600"
                                            >
                                                <span>Dokument hochladen</span>
                                                <input
                                                    id={`file-upload-${id}`}
                                                    name="file-upload"
                                                    type="file"
                                                    className="sr-only"
                                                    onChange={handleFileChange}
                                                />
                                            </label>
                                        </div>
                                        <span className="m-2 text-xs leading-5 text-gray-600 font-medium">PDF, DOCX, PPTX und TXT werden unterstützt</span>
                                        {errorMessage && (
                                            <div className="mt-6 text-red-500 text-sm text-center">{errorMessage}</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="mt-6">
                            <div className="block font-semibold leading-6 text-sm text-gray-900">
                                Informationen
                            </div>
                            <div className="mt-2 flex justify-center">
                                <div
                                    className="block w-full border-0 py-2 text-gray-900 sm:leading-6">
                                    erstellt
                                    von {user.first_name} {user.last_name}
                                    <span
                                        className="mx-2 inline-flex items-center rounded-md bg-gray-200 px-2 py-1 text-xs font-medium text-gray-700">{tenant.name}</span>
                                    am {formatDate(created_at)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default KnowledgeBaseItem;
