import React, {useEffect, useState, useCallback} from "react";
import WorkflowItem from "../components/WorkflowItem";
import {Link} from "react-router-dom";
import {Workflow} from "../types";
import {getToken} from "../utils/authentication";
import SearchBar from "../components/SearchBar";
import PaginationBar from "../components/PaginationBar";
import {BoltIcon, CircleStackIcon} from "@heroicons/react/16/solid";

const Workflows: React.FC = () => {
    const [workflows, setWorkflows] = useState<Workflow[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const [searchTerm, setSearchTerm] = useState<string>("");
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);

    useEffect(() => {
        const timer = setTimeout(() => {
            setDebouncedSearchTerm(searchTerm);
        }, 300);

        return () => clearTimeout(timer);
    }, [searchTerm]);

    const fetchWorkflows = useCallback(async () => {
        setLoading(true);
        setErrorMessage(null);

        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/workflows?search=${debouncedSearchTerm}&page=${currentPage}&size=10`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${getToken()}`,
                    },
                }
            );

            if (response.ok) {
                const data = await response.json();
                setWorkflows(data.items);
                setTotalPages(data.pages);
            } else {
                const data = await response.json();
                setErrorMessage(data.detail || "Ein Fehler ist aufgetreten.");
            }
        } catch (error) {
            setErrorMessage("Ein Fehler ist aufgetreten.");
        } finally {
            setLoading(false);
        }
    }, [debouncedSearchTerm, currentPage]);

    useEffect(() => {
        fetchWorkflows();
    }, [fetchWorkflows]);

    const handleSearchChange = useCallback((value: string) => {
        setSearchTerm(value);
        setCurrentPage(1);
    }, []);

    const handlePageChange = (newPage: number) => {
        setCurrentPage(newPage);
    };

    // if (loading) {
    //     return (
    //         <div className="animate-ping rounded-full bg-slate-300 h-10 w-10 m-auto"></div>
    //     );
    // }

    if (errorMessage) {
        return (
            <div className="mt-1 text-sm text-center text-gray-500">{errorMessage}</div>
        );
    }

    return (
        <div className="pb-14">
            <div className="flex justify-between mb-4">
                <div>
                    <h2 className="text-xl font-bold text-left mb-2 leading-6 text-gray-900">
                        Workflows
                    </h2>
                    <p className="mt-1 text-sm font-medium text-left text-gray-500">
                        Automatisierung durch vordefinierte Abläufe
                    </p>
                </div>
                <Link to="/workflows/create">
                    <button
                        className="flex justify-center items-center ml-auto mt-5 py-2 px-3 mb-2 rounded-xl text-sm text-white font-medium cursor-pointer hover:bg-blue-700 bg-blue-600">
                        <BoltIcon className="w-4 h-4 mr-1.5"></BoltIcon>
                        Workflow erstellen
                    </button>
                </Link>
            </div>

            <SearchBar searchTerm={searchTerm} onSearchChange={handleSearchChange}/>

            <hr className="z-10 my-6"/>

            {workflows.map((workflow) => (
                <WorkflowItem {...workflow} key={workflow.id}/>
            ))}

            <PaginationBar
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
            />
        </div>
    );
};

export default Workflows;
