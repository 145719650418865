import React, {useContext, useState} from "react";
import "./MessageItem.css";
import {ChevronUpIcon, DocumentDuplicateIcon, EyeSlashIcon, LockClosedIcon, ShareIcon} from "@heroicons/react/16/solid";
import {Link, useNavigate} from "react-router-dom";
import {Role, Workflow} from "../types";
import {UserContext} from "../App";
import {formatDate} from "../utils/utils";
import {SubmitHandler} from "react-hook-form";
import {getToken} from "../utils/authentication";

const WorkflowItem: React.FC<Workflow> = ({
                                              id,
                                              user,
                                              tenant,
                                              title,
                                              context,
                                              action,
                                              visible_for_creator,
                                              visible_for_converter,
                                              shared_with_tenant,
                                              is_template,
                                              is_disabled,
                                              created_at
                                          }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const toggleAccordion = () => setIsOpen(!isOpen);
    const navigate = useNavigate();

    const {currentUser} = useContext(UserContext);

    const handleDuplicate = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + `/workflows/${id}/duplicate`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": 'Bearer ' + getToken(),
                },
            });

            if (response.ok) {
                const data = await response.json();
                navigate(`/workflows/edit/${data.id}`);
            } else {
                const data = await response.json();
                console.error(data.detail || "Ein Fehler ist aufgetreten.");
            }
        } catch (error) {
            console.error("Ein Fehler ist aufgetreten.");
        } finally {
            console.error(false);
        }
    };

    return (
        <div className="mt-5">
            <button
                type="button"
                className={`flex items-center justify-between w-full p-3 pl-4 text-left font-semibold font-base text-gray-900 border-b rounded-xl cursor-auto + ${isOpen ? "rounded-b-none" : ""} + ${is_disabled ? "bg-gray-300" : "bg-white"}`}
            >
                <div>
                    <span className="mr-4">{title}</span>
                    {visible_for_creator &&
                        <span
                            className="my-1 inline-flex items-center rounded-md bg-green-100 px-2 py-1 mr-2 text-xs font-medium text-green-700">Content Creator</span>
                    }
                    {visible_for_converter &&
                        <span
                            className="my-1 inline-flex items-center rounded-md bg-purple-100 px-2 py-1 text-xs font-medium text-purple-700">Content Converter</span>
                    }
                </div>
                <div className="flex items-center">
                    {is_disabled &&
                        <EyeSlashIcon className="h-5 w-5 mr-4 text-gray-400"
                                      aria-hidden="true"/>
                    }
                    {shared_with_tenant &&
                        <ShareIcon className="h-5 w-5 mr-4 text-gray-400"
                                   aria-hidden="true"/>
                    }
                    {is_template &&
                        <span
                            className="ml-2 mr-4 inline-flex items-center rounded-md bg-gray-200 px-2 py-1 text-xs font-medium text-gray-700">Template
                            {(currentUser?.role === Role.SuperAdmin) &&
                                <>
                                    <div
                                        className="mx-1.5 border-r-2 border-r-gray-300 h-3">
                                    </div>
                                    <span>{tenant.name}</span>
                                </>
                            }
                        </span>
                    }
                    <ChevronUpIcon
                        className={`ml-2 w-7 h-7 ${isOpen ? "rotate-0" : "rotate-180"} shrink-0 cursor-pointer text-gray-600 hover:text-gray-700`}
                        onClick={toggleAccordion}
                    />
                </div>
            </button>
            <div className={`${isOpen ? "block" : "hidden"}`}>
                <div className="border-gray-200 bg-white rounded-b-xl">
                    <div className="rounded-xl bg-white p-5">
                        <div className="flex justify-end">
                            <button
                                className="py-2 px-3 mr-3 rounded-xl text-sm text-white font-medium cursor-pointer hover:bg-gray-500 bg-gray-400"
                                onClick={handleDuplicate}
                            >
                                duplizieren
                            </button>
                            {((!is_template || currentUser?.role === Role.SuperAdmin) &&

                                <Link to={`/workflows/edit/${id}`} className="inline-block">
                                    <button
                                        className="py-2 px-3 rounded-xl text-sm text-white font-medium cursor-pointer hover:bg-gray-600 bg-gray-500">
                                        bearbeiten
                                    </button>
                                </Link>
                            )}
                        </div>

                        <div className="mb-4">
                            <div
                                className="block font-semibold leading-6 text-sm text-gray-900">
                                Kontext
                            </div>
                            <div className="mt-2">
                                <div
                                    className="block w-full border-0 py-2 text-gray-900 sm:leading-6 whitespace-break-spaces">
                                    {context}
                                </div>
                            </div>
                        </div>

                        <div className="mb-4">
                            <div className="block font-semibold leading-6 text-sm text-gray-900">
                                Aktion
                            </div>
                            <div className="mt-2">
                                <div
                                    className="block w-full border-0 py-2 text-gray-900 sm:leading-6 whitespace-break-spaces">
                                    {action}
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className="block font-semibold leading-6 text-sm text-gray-900">
                                Informationen
                            </div>
                            <div className="mt-2 flex justify-center">
                                <div
                                    className="block w-full border-0 py-2 text-gray-900 sm:leading-6">
                                    erstellt
                                    von {user.first_name} {user.last_name}
                                    <span
                                        className="mx-2 inline-flex items-center rounded-md bg-gray-200 px-2 py-1 text-xs font-medium text-gray-700">{tenant.name}</span>
                                    am {formatDate(created_at)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WorkflowItem;
