import React, {useContext, useEffect, useState} from "react";
import {SubmitHandler, useForm} from "react-hook-form";
import {Link, useNavigate, useParams} from "react-router-dom";
import {ChevronLeftIcon} from "@heroicons/react/16/solid";
import {Archive} from "../types";
import {getToken} from "../utils/authentication";
import {UserContext} from "../App";

interface ArchiveFormValues {
    title: string;
    history: string;
    shared_with_tenant: boolean;
}

const ArchiveEdit: React.FC = () => {
    const navigate = useNavigate();
    const {id} = useParams<{ id: string }>();
    const {
        register,
        handleSubmit,
        setValue,
        formState: {errors},
    } = useForm<ArchiveFormValues>();
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const {currentUser} = useContext(UserContext);


    useEffect(() => {
        const fetchArchive = async () => {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_API_URL}/archives/${id}`,
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": 'Bearer ' + getToken(),
                        },
                    },
                );

                if (response.ok) {
                    const data = await response.json();
                    setValue("title", data.title);
                    setValue("history", data.history);
                    setValue("shared_with_tenant", data.shared_with_tenant);
                } else {
                    const data = await response.json();
                    setErrorMessage(data.detail || "Ein Fehler ist aufgetreten.");
                }
            } catch (error) {
                setErrorMessage("Ein Fehler ist aufgetreten.");
            }
        };

        if (id) {
            fetchArchive();
        }
    }, [id, setValue]);

    const onSubmit: SubmitHandler<ArchiveFormValues> = async (data) => {
        setLoading(true);
        setSuccessMessage(null);
        setErrorMessage(null);

        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/archives/${id}`,
                {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": 'Bearer ' + getToken(),
                    },
                    body: JSON.stringify(data),
                },
            );

            if (response.ok) {
                setSuccessMessage("Archiv erfolgreich aktualisiert!");
                navigate("/archives");
            } else {
                const data = await response.json();
                setErrorMessage(data.detail || "Ein Fehler ist aufgetreten.");
            }
        } catch (error) {
            setErrorMessage("Ein Fehler ist aufgetreten.");
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async () => {
        setSuccessMessage(null);
        setErrorMessage(null);

        const confirmDelete = window.confirm(
            "Soll das Archiv wirklich gelöscht werden?",
        );
        if (!confirmDelete) {
            return;
        }

        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/archives/${id}`,
                {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": 'Bearer ' + getToken(),
                    },
                },
            );

            if (response.ok) {
                setSuccessMessage("Archiv erfolgreich gelöscht!");
                navigate("/archives");
            } else {
                const data = await response.json();
                setErrorMessage(data.detail || "Ein Fehler ist aufgetreten.");
            }
        } catch (error) {
            setErrorMessage("Ein Fehler ist aufgetreten.");
        }
    };

    return (
        <div className="pb-14">
            <div className="flex justify-center mx-auto">
                <Link to="/archives" className="inline-block">
                    <button className="flex mt-4 mb-8 rounded-md text-sm font-medium leading-6 text-gray-500">
                        <ChevronLeftIcon className="h-6 w-6 items-center mr-1"></ChevronLeftIcon>
                        zurück
                    </button>
                </Link>
            </div>

            <form
                className="border-b rounded-xl bg-white p-5"
                onSubmit={handleSubmit(onSubmit)}
            >
                {successMessage && (
                    <div className="mb-4 text-green-500 text-sm">{successMessage}</div>
                )}
                {errorMessage && (
                    <div className="mb-4 text-red-500 text-sm">{errorMessage}</div>
                )}

                <div className="mb-4">
                    <label
                        htmlFor="title"
                        className="block text-sm font-medium leading-6 text-gray-900"
                    >
                        Titel
                    </label>
                    <div className="mt-2">
                        <input
                            id="title"
                            {...register("title", {required: true})}
                            type="text"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 text-sm"
                        />
                        {errors.title && (
                            <p className="mt-2 text-red-500 text-sm">
                                Dieses Feld ist erforderlich.
                            </p>
                        )}
                    </div>
                </div>

                <div className="my-6">
                    <div className="relative flex ml-2">
                        <div className="flex h-6 items-center">
                            <input
                                id="shared_with_tenant"
                                {...register("shared_with_tenant")}
                                type="checkbox"
                                className="form-checkbox h-5 w-5 rounded text-blue-600 focus:ring-0 focus:ring-offset-0"
                            />
                        </div>
                        <div className="ml-3 text-sm leading-6">
                            <label
                                htmlFor="shared_with_tenant"
                                className="block text-sm font-medium leading-6 text-gray-900"
                            >
                                Mit allen Benutzer:innen des Tenants <span
                                className="mx-1 inline-flex items-center rounded-md bg-gray-200 px-2 py-1 text-xs font-medium text-gray-700">{currentUser?.tenant.name}</span> teilen
                            </label>
                        </div>
                    </div>
                </div>

                <div>
                    <button
                        type="submit"
                        className={`flex w-full justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-9 items-center ${loading ? 'opacity-60 cursor-not-allowed' : ''}`}
                    >
                        {loading ? (
                            <div className="flex space-x-5">
                                <span className="animate-ping w-1 h-1 rounded-full bg-white opacity-80"></span>
                                <span className="animate-ping w-1 h-1 rounded-full bg-white opacity-80"></span>
                                <span className="animate-ping w-1 h-1 rounded-full bg-white opacity-80"></span>
                            </div>
                        ) : (
                            "aktualisieren"
                        )}
                    </button>
                </div>
            </form>

            <div className="mt-8">
                <button
                    type="button"
                    onClick={handleDelete}
                    className="flex justify-center mx-auto rounded-md text-sm font-medium leading-6 text-red-600"
                >
                    Archiv löschen
                </button>
            </div>
        </div>
    );
};

export default ArchiveEdit;
