import React, {useState} from "react";
import {ChevronUpIcon, ShareIcon} from "@heroicons/react/16/solid";
import {Archive, Message} from "../types";
import MessageItem from "./MessageItem";
import {Link} from "react-router-dom";
import {formatDate} from "../utils/utils";

const ArchiveItem: React.FC<Archive> = ({
                                            id,
                                            user,
                                            tenant,
                                            title, history,
                                            shared_with_tenant,
                                            created_at
                                        }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const toggleAccordion = () => setIsOpen(!isOpen);
    const messages: Message[] = JSON.parse(history);

    return (
        <div className="mt-5">
            <button
                type="button"
                className={`flex items-center justify-between w-full p-3 pl-4 text-left font-semibold font-base text-gray-900 border-b rounded-xl bg-white cursor-auto + ${isOpen ? "rounded-b-none" : ""}`}
            >
                <span>{title}</span>
                <div className="flex items-center">
                    {shared_with_tenant &&
                        <ShareIcon className="h-5 w-5 mr-4 text-gray-400"
                                   aria-hidden="true"/>
                    }
                    <ChevronUpIcon
                        className={`ml-2 w-7 h-7 ${isOpen ? "rotate-0" : "rotate-180"} shrink-0 cursor-pointer text-gray-600 hover:text-gray-700`}
                        onClick={toggleAccordion}
                    />
                </div>
            </button>
            <div className={`${isOpen ? "block" : "hidden"}`}>
                <div className="border-gray-20 bg-white rounded-b-xl">
                    <div className="rounded-xl bg-white p-5">
                        <div className="flex justify-end">
                            <Link to={`/archives/edit/${id}`} className="inline-block">
                                <button
                                    className="py-2 px-3 rounded-xl text-sm text-white font-medium cursor-pointer hover:bg-gray-600 bg-gray-500">
                                    bearbeiten
                                </button>
                            </Link>
                        </div>
                        <div className="mt-2">
                            <div className="block w-full border-0 py-2 text-gray-900 sm:leading-6">
                                {messages.map(
                                    (message, i) =>
                                        message.role !== "system" && (
                                            <MessageItem {...message} key={i}/>
                                        ),
                                )}
                            </div>
                        </div>
                        <div className="mt-6">
                            <div className="block font-semibold leading-6 text-sm text-gray-900">
                                Informationen
                            </div>
                            <div className="mt-2 flex justify-center">
                                <div
                                    className="block w-full border-0 py-2 text-gray-900 sm:leading-6">
                                    erstellt
                                    von {user.first_name} {user.last_name}
                                    <span
                                        className="mx-2 inline-flex items-center rounded-md bg-gray-200 px-2 py-1 text-xs font-medium text-gray-700">{tenant.name}</span>
                                    am {formatDate(created_at)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ArchiveItem;
