import React, {useState, useContext, useEffect} from 'react';
import {Dialog} from '@headlessui/react';
import {
    Bars3Icon,
    UsersIcon,
    XMarkIcon,
    ArchiveBoxIcon,
    KeyIcon,
    BoltIcon,
    ChatBubbleBottomCenterTextIcon,
    CircleStackIcon,
    Cog6ToothIcon,
    DocumentTextIcon,
    UserIcon,
    ChatBubbleOvalLeftEllipsisIcon,
    BuildingOfficeIcon,
    BuildingOffice2Icon,
    ChevronRightIcon
} from '@heroicons/react/16/solid';
import {classNames} from "../utils/utils";
import {Outlet, useLocation, Link, useNavigate} from "react-router-dom";
import {UserContext} from "../App";
import {Role} from "../types";
import {removeToken, removeUser} from "../utils/authentication";
import {InformationCircleIcon} from "@heroicons/react/24/outline";

interface NavigationItem {
    name: string;
    href: string;
    icon: React.ElementType;
    current: boolean;
    hidden: boolean;
    navigationGroup: 'Allgemein' | 'Admin' | 'Tenant';
}

const MainLayout: React.FC = () => {
    const location = useLocation();
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const {currentUser, setCurrentUser, currentUserToken, setCurrentUserToken} = useContext(UserContext);
    const navigate = useNavigate();

    const [navigationItems, setNavigationItems] = useState<NavigationItem[]>([
        {
            name: "Content Creator",
            href: "/content-creator",
            icon: ChatBubbleBottomCenterTextIcon,
            current: false,
            hidden: false,
            navigationGroup: "Allgemein",
        },
        {
            name: "Content Converter",
            href: "/content-converter",
            icon: DocumentTextIcon,
            current: false,
            hidden: false,
            navigationGroup: "Allgemein",
        },
        {
            name: "Knowledge Bases",
            href: "/knowledgebases",
            icon: CircleStackIcon,
            current: false,
            hidden: false,
            navigationGroup: "Allgemein",
        },
        {
            name: "Workflows",
            href: "/workflows",
            icon: BoltIcon,
            current: false,
            hidden: false,
            navigationGroup: "Allgemein",
        },
        {
            name: "Archive",
            href: "/archives",
            icon: ArchiveBoxIcon,
            current: false,
            hidden: false,
            navigationGroup: "Allgemein",
        },
        {
            name: "Schnittstellen",
            href: "/interfaces",
            icon: KeyIcon,
            current: false,
            hidden: currentUser?.tenant.supports_miles_export === false,
            navigationGroup: "Allgemein",
        },
        {
            name: "Einstellungen",
            href: "/settings",
            icon: Cog6ToothIcon,
            current: false,
            hidden: currentUser?.role === Role.TenantAdmin,
            navigationGroup: "Admin",
        },
        {
            name: "Benutzer:innen",
            href: "/users",
            icon: UsersIcon,
            current: false,
            hidden: false,
            navigationGroup: "Admin",
        },
        {
            name: "Tenants",
            href: "/tenants",
            icon: BuildingOffice2Icon,
            current: false,
            hidden: currentUser?.role === Role.TenantAdmin,
            navigationGroup: "Admin",
        },
        {
            name: "Tenant",
            href: `/tenants/edit/${currentUser?.tenant.id.toString()}`,
            icon: BuildingOfficeIcon,
            current: false,
            hidden: false,
            navigationGroup: "Admin",
        },
        {
            name: currentUser?.tenant?.name || "Tenant",
            href: "/tenant/",
            icon: UsersIcon,
            current: false,
            hidden: false,
            navigationGroup: "Tenant",
        },
    ]);

    useEffect(() => {
        setNavigationItems((prevNavigationItems) =>
            prevNavigationItems.map((item) => ({
                ...item,
                current: location.pathname === item.href,
            })),
        );

        window.scrollTo(0, 0);
    }, [location]);


    const handleLogout = () => {
        removeToken();
        removeUser();
        setCurrentUser(undefined);
        setCurrentUserToken('');
        navigate('/login');
    };

    const renderNavigationGroup = (groupName: 'Allgemein' | 'Admin' | 'Tenant') => {
        const items = navigationItems.filter(
            (item) => item.navigationGroup === groupName && !item.hidden
        );

        if (items.length === 0) return null;

        return (
            <li key={groupName}>
                {groupName !== "Allgemein" &&
                    <div className="my-2 text-xs font-semibold text-gray-400">{groupName}</div>}
                <ul role="list" className="-mx-2 space-y-1">
                    {items.map((item) => (
                        <li key={item.name}>
                            <Link
                                to={item.href}
                                className={classNames(
                                    item.current
                                        ? 'bg-gray-800 text-white'
                                        : 'text-gray-400 hover:bg-gray-800 hover:text-white',
                                    'group flex gap-x-3 rounded-md p-2 text-sm font-semibold items-center',
                                )}
                            >
                                <item.icon aria-hidden="true" className="h-6 w-6 shrink-0 mr-1"/>
                                {item.name}
                            </Link>
                        </li>
                    ))}
                </ul>
            </li>
        );
    };

    const renderUserSection = () => (
        <li className="mt-auto mb-4">
            <div className="mt-2 -mx-2 justify-between flex">
                <Link
                    to="/account"
                    className="group flex gap-x-3 rounded-md p-2 text-sm font-semibold items-center text-gray-400 hover:bg-gray-800 hover:text-white"
                >
                    <UserIcon aria-hidden="true" className="h-6 w-6 shrink-0 mr-1"/>
                    <span className="truncate pr-2">{currentUser?.first_name + " " + currentUser?.last_name}</span>
                </Link>
                <ChevronRightIcon aria-hidden="true" onClick={handleLogout}
                                  className="h-6 w-6 shrink-0 text-gray-400 hover:text-white mt-1.5 cursor-pointer"/>
            </div>
        </li>
    );

    return (
        <>
            <div>
                {/* Mobile Sidebar */}
                <Dialog open={sidebarOpen} onClose={setSidebarOpen} className="relative z-50 lg:hidden">
                    <Dialog.Overlay
                        className="fixed inset-0 bg-gray-900/80 transition-opacity duration-300 ease-linear"/>
                    <div className="fixed inset-0 flex">
                        <Dialog.Panel
                            className="relative mr-16 flex w-full max-w-xs flex-1 transform transition duration-300 ease-in-out data-[closed]:-translate-x-full">
                            <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                                <button
                                    type="button"
                                    onClick={() => setSidebarOpen(false)}
                                    className="-m-2.5 p-2.5 outline-0"
                                >
                                    <XMarkIcon aria-hidden="true" className="h-6 w-6 text-white"/>
                                </button>
                            </div>
                            <div
                                className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-2 ring-1 ring-white/10">
                                <div className="flex mt-8 mb-4">
                                    <ChatBubbleOvalLeftEllipsisIcon
                                        className="h-6 w-6 text-blue-500"
                                        aria-hidden="true"
                                    />
                                    <span className="px-3 text-base font-bold text-blue-500">Content AI</span>
                                </div>
                                <nav className="flex flex-1 flex-col">
                                    <ul role="list" className="flex flex-1 flex-col gap-y-7">
                                        {renderNavigationGroup("Allgemein")}
                                        {currentUser?.role === Role.TenantAdmin || currentUser?.role === Role.SuperAdmin
                                            ? renderNavigationGroup("Admin")
                                            : null}
                                        {renderNavigationGroup("Tenant")}
                                        {renderUserSection()}
                                    </ul>
                                </nav>
                            </div>
                        </Dialog.Panel>
                    </div>
                </Dialog>

                {/* Desktop Sidebar */}
                <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
                    <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-8">
                        <div className="flex h-16 shrink-0 items-center mt-4">
                            <ChatBubbleOvalLeftEllipsisIcon
                                className="h-8 w-8 text-blue-500"
                                aria-hidden="true"
                            />
                            <span className="px-3 text-base font-bold text-blue-500">Content AI</span>
                        </div>
                        <nav className="flex flex-1 flex-col">
                            <ul role="list" className="flex flex-1 flex-col gap-y-7">
                                {renderNavigationGroup("Allgemein")}
                                {currentUser?.role === Role.TenantAdmin || currentUser?.role === Role.SuperAdmin
                                    ? renderNavigationGroup("Admin")
                                    : null}
                                {renderNavigationGroup("Tenant")}
                                {renderUserSection()}
                            </ul>
                        </nav>
                    </div>
                </div>

                {/* Top bar */}
                <div
                    className="sticky top-0 z-40 flex items-center gap-x-6 bg-gray-900 px-4 py-4 shadow-sm sm:px-6 lg:hidden justify-between">
                    <div className="flex">
                        <ChatBubbleOvalLeftEllipsisIcon
                            className="h-6 w-6 text-blue-500"
                            aria-hidden="true"
                        />
                        <span className="px-3 text-base font-bold text-blue-500">Content AI</span>
                    </div>
                    <button
                        type="button"
                        onClick={() => setSidebarOpen(true)}
                        className="-m-2.5 p-2.5 text-gray-400 lg:hidden outline-0"
                    >
                        <Bars3Icon aria-hidden="true" className="h-6 w-6"/>
                    </button>
                </div>

                {/* Main Content */}
                <main className="pt-8 lg:pt-16 lg:pl-72">
                    <div className="px-6 lg:px-8 m-auto w-full max-w-[880px]">
                        <Outlet/>
                    </div>
                    <div className="absolute top-16 right-2 lg:top-6 lg:right-6 flex space-x-2">
                        <Link to="about">
                            <div
                                className="py-1 px-2 rounded-lg text-sm font-medium border border-gray-300 text-gray-400 flex items-center hover:bg-gray-300">
                                <InformationCircleIcon className="h-5 w-5"/>
                            </div>
                        </Link>
                        <div className="py-1 px-2 rounded-lg text-sm font-medium border border-gray-300 text-gray-400">
                            {process.env.REACT_APP_VERSION}
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
};

export default MainLayout;
