import React, {useEffect, useState} from "react";
import {SubmitHandler, useForm} from "react-hook-form";
import {getToken} from "../utils/authentication";
import {Environment, Role} from "../types";

interface SettingsFormValues {
    system_prompt: string;
}

const Settings: React.FC = () => {
    const {
        register,
        handleSubmit,
        setValue,
        formState: {errors},
    } = useForm<SettingsFormValues>();
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [environment, setEnvironment] = useState<Environment | null>(null);

    useEffect(() => {
        const fetchSettings = async () => {
            try {
                const response = await fetch(
                    process.env.REACT_APP_API_URL + "/settings",
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": 'Bearer ' + getToken(),
                        },
                    },
                );

                if (response.ok) {
                    const data = await response.json();
                    setValue("system_prompt", data.system_prompt);
                } else {
                    const data = await response.json();
                    setErrorMessage(data.detail || "Ein Fehler ist aufgetreten.");
                }
            } catch (error) {
                setErrorMessage("Ein Fehler ist aufgetreten.");
            }
        };

        const fetchEnvironment = async () => {
            try {
                const response = await fetch(
                    process.env.REACT_APP_API_URL + "/environment",
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": 'Bearer ' + getToken(),
                        },
                    },
                );

                if (response.ok) {
                    const data: Environment = await response.json();
                    setEnvironment(data);
                } else {
                    const data = await response.json();
                    setErrorMessage(data.detail || "Ein Fehler ist aufgetreten.");
                }
            } catch (error) {
                setErrorMessage("Ein Fehler ist aufgetreten.");
            }
        };

        fetchSettings();
        fetchEnvironment();
    }, [setValue]);

    const onSubmit: SubmitHandler<SettingsFormValues> = async (data) => {
        setLoading(true);
        setSuccessMessage(null);
        setErrorMessage(null);

        try {
            const response = await fetch(
                process.env.REACT_APP_API_URL + "/settings",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": 'Bearer ' + getToken(),
                    },
                    body: JSON.stringify(data),
                },
            );

            if (response.ok) {
                setSuccessMessage("Einstellungen erfolgreich aktualisiert!");
            } else {
                const data = await response.json();
                setErrorMessage(data.detail || "Ein Fehler ist aufgetreten.");
            }
        } catch (error) {
            setErrorMessage("Ein Fehler ist aufgetreten.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="pb-14">
            <div>
                <h2 className="text-xl font-bold text-left mb-3 leading-6 text-gray-900">
                    Einstellungen
                </h2>
                <p className="mt-1 mr-4 text-sm font-medium text-left text-gray-500">
                    <span className="mr-3">Globale Parameter</span>
                    <span
                        className="my-2 inline-flex items-center rounded-md px-2 py-1 text-xs font-medium border border-blue-700/20 bg-blue-50 text-blue-700">Super Admin Privileg</span>
                </p>
                <hr className="z-10 mb-8"/>
            </div>
            <form className="border-b rounded-xl bg-white p-5" onSubmit={handleSubmit(onSubmit)}>
                {successMessage && (
                    <div className="mb-4 text-green-500 text-sm">{successMessage}</div>
                )}
                {errorMessage && (
                    <div className="mb-4 text-red-500 text-sm">{errorMessage}</div>
                )}
                <div className="mb-4">
                    <label
                        htmlFor="system_prompt"
                        className="block text-sm font-medium leading-6 text-gray-900"
                    >
                        System Prompt
                    </label>
                    <div className="mt-2">
                        <textarea
                            id="system_prompt"
                            {...register("system_prompt", {required: true})}
                            rows={6}
                            aria-describedby="system-prompt-description"
                            className="block w-full rounded-md border-0 mt-2 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                        />
                        {errors.system_prompt && (
                            <p className="mt-2 text-red-500 text-sm">
                                Dieses Feld ist erforderlich.
                            </p>
                        )}
                    </div>
                    <p
                        id="system-prompt-description"
                        className="mt-2 text-sm text-gray-500"
                    >
                        Welche Identität soll die Anwendung tenant-übergreifend erhalten?
                    </p>
                </div>
                <div>
                    <button
                        type="submit"
                        className={`flex w-full justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-9 items-center ${loading ? 'opacity-60 cursor-not-allowed' : ''}`}
                    >
                        {loading ? (
                            <div className="flex space-x-5">
                                <span className="animate-ping w-1 h-1 rounded-full bg-white opacity-80"></span>
                                <span className="animate-ping w-1 h-1 rounded-full bg-white opacity-80"></span>
                                <span className="animate-ping w-1 h-1 rounded-full bg-white opacity-80"></span>
                            </div>
                        ) : (
                            "speichern"
                        )}
                    </button>
                </div>
            </form>

            {environment && (
                <form className="border-b rounded-xl bg-white p-5 mt-6">
                    <div className="mb-4">
                        <label className="block text-sm font-medium leading-6 text-gray-900">Frontend
                            Environment</label>
                        <input
                            type="text"
                            value={process.env.REACT_APP_ENV}
                            readOnly
                            className="block w-full bg-gray-100 rounded-md border-0 mt-2 py-1.5 text-gray-500 ring-1 ring-inset ring-gray-300 focus:ring-inset focus:ring-gray-300 sm:text-sm sm:leading-6"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-sm font-medium leading-6 text-gray-900">Frontend Version</label>
                        <input
                            type="text"
                            value={process.env.REACT_APP_VERSION}
                            readOnly
                            className="block w-full bg-gray-100 rounded-md border-0 mt-2 py-1.5 text-gray-500 ring-1 ring-inset ring-gray-300 focus:ring-inset focus:ring-gray-300 sm:text-sm sm:leading-6"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-sm font-medium leading-6 text-gray-900">Backend API URL</label>
                        <input
                            type="text"
                            value={process.env.REACT_APP_API_URL}
                            readOnly
                            className="block w-full bg-gray-100 rounded-md border-0 mt-2 py-1.5 text-gray-500 ring-1 ring-inset ring-gray-300 focus:ring-inset focus:ring-gray-300 sm:text-sm sm:leading-6"
                        />
                    </div>
                    <hr/>
                    <div className="mb-4">
                        <label className="block text-sm font-medium leading-6 text-gray-900">Backend Environment</label>
                        <input
                            type="text"
                            value={environment.backend_environment}
                            readOnly
                            className="block w-full bg-gray-100 rounded-md border-0 mt-2 py-1.5 text-gray-500 ring-1 ring-inset ring-gray-300 focus:ring-inset focus:ring-gray-300 sm:text-sm sm:leading-6"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-sm font-medium leading-6 text-gray-900">Backend Version</label>
                        <input
                            type="text"
                            value={environment.backend_version}
                            readOnly
                            className="block w-full bg-gray-100 rounded-md border-0 mt-2 py-1.5 text-gray-500 ring-1 ring-inset ring-gray-300 focus:ring-inset focus:ring-gray-300 sm:text-sm sm:leading-6"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-sm font-medium leading-6 text-gray-900">Email CC</label>
                        <input
                            type="text"
                            value={environment.email_cc}
                            readOnly
                            className="block w-full bg-gray-100 rounded-md border-0 mt-2 py-1.5 text-gray-500 ring-1 ring-inset ring-gray-300 focus:ring-inset focus:ring-gray-300 sm:text-sm sm:leading-6"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-sm font-medium leading-6 text-gray-900">Azure OpenAI
                            Endpoint</label>
                        <input
                            type="text"
                            value={environment.azure_openai_endpoint}
                            readOnly
                            className="block w-full bg-gray-100 rounded-md border-0 mt-2 py-1.5 text-gray-500 ring-1 ring-inset ring-gray-300 focus:ring-inset focus:ring-gray-300 sm:text-sm sm:leading-6"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-sm font-medium leading-6 text-gray-900">Azure LLM
                            Deployment</label>
                        <input
                            type="text"
                            value={environment.azure_openai_deployment_llm}
                            readOnly
                            className="block w-full bg-gray-100 rounded-md border-0 mt-2 py-1.5 text-gray-500 ring-1 ring-inset ring-gray-300 focus:ring-inset focus:ring-gray-300 sm:text-sm sm:leading-6"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-sm font-medium leading-6 text-gray-900">Azure Embedding
                            Deployment</label>
                        <input
                            type="text"
                            value={environment.azure_openai_deployment_embedding}
                            readOnly
                            className="block w-full bg-gray-100 rounded-md border-0 mt-2 py-1.5 text-gray-500 ring-1 ring-inset ring-gray-300 focus:ring-inset focus:ring-gray-300 sm:text-sm sm:leading-6"
                        />
                    </div>
                </form>
            )}
        </div>
    );
};

export default Settings;
